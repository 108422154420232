import React from "react";
import '../Home/home.css'
import Offer from '../Home/About/about'
// import Products from '../Home/Products/Products'
import Categories from '../Home/categories/categories'
import Products from "./Products/Products";
import Services from "./Services/services";
import CounterStats from '../Home/Key/key'
import CoreValues from "./Values/corevalues";
import Client from "./Client/client";
import Contact from "./Contact/contact";



function Home() {
  return (
    <>
      <main className="main-content">

        <section id="about">
         <Offer />
         <CoreValues/>
        </section>

        <section id="products">
          <Products/>  
        </section>

        <section id="services">
          <Services/>  
        </section>

        <section id="key">
        <CounterStats/>
        </section>

        <section id="client">
        <Client />
        </section>

        <section id="contact">
        <Contact />
        </section>
      </main>
    </>
  );
}

export default Home;
