import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import Header from "./component/Header/header";
import Navbar from './component/Header/navbar';
import Carouselslider from '../src/pages/Home/Slider/banner'
import Footer from './component/footer/footer';
import Home from './pages/Home/Home';



function App() {
  const logout = () => {
    localStorage.removeItem("signUp");
    window.location.reload();
  };

  const deleteAccount = () => {
    localStorage.clear();
    window.location.reload();
  };

  const [cartCount, setCartCount] = React.useState(0);
  const [loggedIn, setLoggedIn] = React.useState(!!localStorage.getItem('signUp'));

  const handleLogin = () => setLoggedIn(true);
  const addToCart = () => setCartCount(cartCount + 1);

  return (
    <Router>
      <Header />
      <Navbar />
      <Carouselslider/>
      <Home />
      <Footer />
    </Router>
  );
}

export default App;
