import React from 'react';
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import './footer.css';
import footLogo from '../../assets/img/footer-logo.webp';
import Twitter from '../../assets/img/twitter.png';
import Facebook from '../../assets/img//fb.png';
import Insta from '../../assets/img/insta.png';
import Flicker from '../../assets/img/flicker.png';
import Linkdin from '../../assets/img/linkdin.png';
import Youtube from '../../assets/img/ytb.png';
import Blogger from '../../assets/img/blog.png';
import Soundcloud from '../../assets/img/soundcloud.png';


const Footer = () => {
  return (
  
    <div className='footer-wrapper' id='contact'>   
      <div className='footer'>
        <Container>
        <div className='foot-logo animateIn="fadeIn offset-default 150'>
              <img className="mb-3" src={footLogo} alt="First slide" />
              </div>  
        <div className="row">
        <div className="col-md-4 col-sm-12">
              <div className='foot-link'>      
                         <ul>
                                <li>
                                <div className="icon">
                                    <span><i className="fa fa-map-marker" aria-hidden="true"></i> <strong>Address : </strong></span>
                                    </div>
                                    <div className="content">
                                    <Link to="https://maps.app.goo.gl/2Lbugzt8sjyNT7DB6" target="_blank">Shop No.9, Srinivas Tower, City View Building, S.N. Path,
                                      Hanuman Lane, Lower Parel, Mumbai - 400013.</Link>
                                    </div>
                                </li>
                                <li>
                                <div className="icon">
                                   
                                    <span><i className="fa fa-envelope-o" aria-hidden="true"></i> <strong>Email:</strong></span>
                                    </div>
                                    <div className="content">
                                    <Link to="/mailto:norenterprises21@gmail.com">norenterprises21@gmail.com</Link>
                                    </div>
                                </li>
                                <li>
                                <div className="icon">
                                   
                                    <span><i className="fa fa-mobile" aria-hidden="true"></i> <strong>Call Us:</strong></span>
                                    </div>
                                    <div className="content">
                                    <Link to="/tel:919819031265">+91 9819031265</Link> / <Link to="/tel:919892672189"> 9892672189</Link>
                                    <Link to="/tel:919819031265">+91 9819031265</Link> / <Link to="/tel:919819971110"> 9867575544</Link>
                                    </div>
                                </li>
                                </ul>
              </div>
              </div>
            
              <div className="col-md-7 offset-md-1 col-sm-12">
                <div className="row">
              <div className="col-md-4 col-sm-12">
              <div className='foot-user pl-30'>         
                <ul className=''>
                <h6>Explore</h6>   
                  <li><Link to="/">About Us</Link></li> 
                  <li><Link to="/">Services</Link></li>
                  <li><Link to="/">Careers</Link></li>
                  <li><Link to="/">Blog</Link></li>
                </ul>
              </div>
              </div> 

              <div className="col-md-4 col-sm-12">
              <div className='foot-user'>         
                <ul className=''>
                <h6>Support</h6>   
                  <li><Link to="/">Help</Link></li>
                  <li><Link to="/">Business Solutions</Link></li>
                  <li><Link to="/">Find Stores</Link></li>
                  <li><Link to="/">My Account</Link></li>
                  <li><Link to="/">Track Order</Link></li>
                </ul>
              </div>
              </div> 

              <div className="col-md-4 col-sm-12">
              <div className='foot-user'>         
                <ul className=''>
                <h6>Important Links</h6>   
                <li><Link to="/">Privacy Policy</Link></li>
                <li><Link to="/">Delivery & Return Policy</Link></li>
                <li><Link to="/">Terms & conditions</Link></li>
                </ul>
              </div>
              </div>
              <div className="col-xl-12 text-end d-none d-md-block">
                <div className="content">
                                     
                                      <ul className="social-bar" aria-label="">
                                      <h5 className="follow">Follow Us</h5> 
                                       <li><Link to="https://x.com/precision_grow?s=21" target="_blank">
                                       <img className="social" src={Twitter} alt="twitter" /></Link></li>
      
                                       <li><Link to="https://www.facebook.com/PrecisionGrow/?show_switched_toast=0&amp;show_invite_to_follow=0&amp;show_switched_tooltip=0&amp;show_podcast_settings=0&amp;show_community_review_changes=0&amp;show_community_rollback=0&amp;show_follower_visibility_disclosure=0" target="_blank">
                                       <img className="social" src={Facebook} alt="facebook" /></Link></li>
      
                                       <li><Link to="https://www.instagram.com/precisiongrow_/" target="_blank">
                                       <img className="social" src={Insta} alt="instagram" /></Link></li>
                                       
                                       <li><Link to="https://www.linkedin.com/company/100778255/admin/feed/posts/" target="_blank">
                                       <img className="social" src={Linkdin} alt="linkedin" /></Link></li>
                                 
                                       </ul>  
                                       </div>    
                 
                </div>
                </div>
                
              </div>
            
              </div>     


              <div className="foot-copyright">
            <div className="row align-items-center">
                <div className="col-12 mb-30">
                    <div className="footer-bottom"></div>
                </div>
                <div className="col-xl-12">
                    <p className="font-sm mb-0 text-center">©2024 <Link to ="/"  className="text-brand"><strong>Nor Enterprises</strong></Link>. All Rights Reserved.</p>
                </div>
              
            </div>
        </div>  
              </Container>
      </div>
     
    </div>
  )
}

export default Footer;
