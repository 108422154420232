import React,{useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import '../../Home/About/about.css';
import { motion } from "framer-motion";
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../offer/offer.css'
import abt1 from '../../../assets/img/pro-10.jpg';
import abt2 from '../../../assets/img/abt-2.webp';
import vision from '../../../assets/img/vision.png'
import mission from '../../../assets/img/mission.png'


function Offer() {
    useEffect(() => {
        AOS.init();
      }, [])

      const navigate = useNavigate();
    return(
            <div className="homabt">
                <div className="container">
                        <div className="row">
                            
                                    <div className="col-lg-5 col-md-5">
                                    <div className="offer-img" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1500">
                                                <img src={abt1} />
                                                </div>
                                    </div>
                                      
                                        <div className="col-lg-7 col-md-7">
                                                <div className="homabt-txt" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                                            <motion.h2 initial={{ opacity: 0, scale: 0 }}
                                                    whileInView={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: 0.6 }} viewport={{ once: false }}
                                                    className="abt-h2"><span>About</span> <br/>NOR ENTERPRISES</motion.h2>
                                                    <motion.h4 initial={{ opacity: 0, scale: 0 }}
                                                    whileInView={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: 0.6 }} viewport={{ once: false }}
                                                    className="abt-h4">Empowering Excellence in Printing</motion.h4>
                                                <motion.p initial={{ opacity: 0, scale: 0 }}
                                                    whileInView={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: 0.6 }} viewport={{ once: false }}
                                                    className="abt-p" >NOR Enterprises stands as a beacon of excellence in the
                                                    printing industry, offering comprehensive Pre-press,
                                                    Press, and Post-press services since its inception in
                                                    2012. Founded by visionary leaders Mr. Narendra Nor and Mr.
                                                    Naresh Nor, our journey began with a commitment to
                                                    redefine printing standards through a harmonious
                                                    blend of technology and innovation.</motion.p>
                                                   
                                                    </div>

                                                    <div className="row mis-vis" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <div className="homabt-mission">
                                                        <div className="offer-icon">
                                                            <img src={mission} width="20%" />
                                                        </div>
                                                        <motion.h2 initial={{ opacity: 0, scale: 0 }}
                                                    whileInView={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: 0.6 }} viewport={{ once: false }}
                                                    className="">MISSION</motion.h2>
                                                       
                                                    <motion.p initial={{ opacity: 0, scale: 0 }}
                                                    whileInView={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: 0.6 }} viewport={{ once: false }}
                                                    className="abt-p" >At NOR Enterprises, we set new standards in printing
                                                    excellence by integrating cutting-edge technology with
                                                    creative craftsmanship. Our mission is to exceed expectations
                                                    through quality, innovation, and unwavering dedication to
                                                    customer satisfaction.</motion.p>
                                                   
                                                    </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <div className="homabt-vision">
                                                        <div className="offer-icon">
                                                            <img src={vision} width="20%" />
                                                        </div>
                                                        <motion.h2 initial={{ opacity: 0, scale: 0 }}
                                                    whileInView={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: 0.6 }} viewport={{ once: false }}
                                                    className="">VISION</motion.h2>
                                                        
                                                    <motion.p initial={{ opacity: 0, scale: 0 }}
                                                    whileInView={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: 0.6 }} viewport={{ once: false }}
                                                    className="abt-p" >We envision NOR Enterprises as the benchmark of printing
                                                    excellence globally. We aspire to be the preferred partner for
                                                    businesses seeking high-performance printing solutions that
                                                    blend tradition with cutting-edge technology.</motion.p>
                                                   
                                                    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>



     
    )
}



export default Offer;