import React from 'react';
import Slider from 'react-slick';
import './products.css'; // Make sure to import your CSS here
import pro1 from '../../../assets/img/pro-1.jpg';
import pro2 from '../../../assets/img/pro-2.jpg';
import pro3 from '../../../assets/img/pro-3.jpg';
import pro4 from '../../../assets/img/pro-4.jpg';
import pro5 from '../../../assets/img/pro-5.jpg';
import pro6 from '../../../assets/img/pro-6.jpg';
import pro7 from '../../../assets/img/pro-7.jpg';
import pro8 from '../../../assets/img/pro-8.jpg';
import pro9 from '../../../assets/img/pro-9.jpg';

// Import other images as needed

const categories = [
  { title: "Paper Products", image: pro4, link: "#",
    hoverContent: [
      "Files & Folders",
      "Notepads & Dairies",
      "Registers & Musters",
      "Receipt & Invoice Book",
      "School & College Year Book",
      "Story & Board Book",
      "Booklets & Calendars",
      "Magazines"
    ] 
  },

  { title: "Marketing Collaterals", image: pro3, link: "#", hoverContent: ["Business Cards",
    "Brochure & Flyers",
    "Catalogues",
    "Door Hangers",
    "Vouchers",
    "Gift cards",
    "Packaging",
    "T-Shirts"] },

    { title: "Signage", image: pro5, link: "#", hoverContent: ["Table Covers & Throws",
      "Lanyard & ID Holders",
      "Banners, Flags & Sign",
      "Tabletop Displays",
      "Display Kits",
      "Booth Accessories",
      "Standees & Boards",
      "Name, Tags & Badges",] },

      { title: "Awards", image: pro6, link: "#", hoverContent: ["Medals",
        "Trophies",
        "Coins",
        "Salvers & Frames",
        "Ribbons & Pins",
       " Rings & Belts",
        "Plaques & Lanyards",
        "Certificates"] },

  { title: "Corporate Gifting", image: pro7, link: "#", hoverContent: ["Assorted Dry fruits & Chocolates",
    "Cold & Hot Flask",
    "Bags & Watches",
    "Card Holders",
    "Travel / Gift Card",
    "Scented Candles",
    "Appliances",
    "Customized Products"] },

  { title: "Home & Living", image: pro8, link: "#", hoverContent: ["Home Décor",
   " Bathroom",
    "Towels",
    "Blankets",
    "Pillows & Covers",
    "Rugs & Mats",
    "Canvas",
    "Glassware"] },

  { title: "Personalized", image: pro9, link: "#", hoverContent: ["Invitations",
    "Announcements",
    "Greeting cards",
   " Clothing",
    "Accessories",
    "Jewelry",
    "Drinkware",
   " Bags"] },
  

  { title: "Stationery", image: pro1, link: "#", hoverContent: ["Computer Stationery",
      "Pen",
      "Ink",
      "Marker",
      "Tapes",
      "Calculator",
      "School Items",
      "Stamps"] },

  { title: "Corporate", image: pro2, link: "#", hoverContent: ["Letterheads",
    "Envelopes",
    "Compliment slips",
    "Pocket Folders",
    "Post Cards",
    "Profile & Presentations",
    "Proposals",
    "Annual Reports",] }
];

const Products = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200, // For large devices (desktops)
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // For medium devices (tablets)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For small devices (portrait tablets)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // For extra small devices (phones)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <div className="slider">
      <div className='container-fluid'>
      <div className="heading-txt">
                                <h2>Diverse Product Range</h2>
                                <p>Meeting Every Need</p>
                              
                            </div>
                            <Slider {...settings}>
                                {categories.map((category, index) => (
                                  <div className="row">
                                    <div className="col-md-12" data-aos="zoom-in" data-aos-offset="300"data-aos-duration="1500">
                                  <div key={index} className={`slider-item card rotate-${index + 1}`}>
                                    <img src={category.image} alt={category.title} className="slider-image" />
                                    {category.hoverContent.length > 0 && (
                                      <div className="overlay">
                                        <ul className="text-p">
                                          {category.hoverContent.map((item, i) => (
                                            <li key={i}>
                                              <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> {item}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                    <h3>{category.title}</h3>
                                  </div>
                                  </div>
                                  </div>
                                ))}
                              </Slider>


                                        </div>
                                      </div>
                                    );
                                  };
export default Products;
