import React from 'react'
import '../Values/corevalues.css'
import mission from '../../../assets/img/printer.png'


const CoreValues = () => {
  return (
    <section id="core-value">
    <div className="container">
      <div className="core-txt">
        <h2>Core Values</h2>
        <p>Foundation of Success</p>
      </div>
      <div className="row">
            <div className="col-md-3" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                <div className="core">
                    <img src={mission} /> 
                    <h3>Heritage of Excellence</h3>
                    <p>Built on a legacy of delivering
                    exceptional printing solutions that
                    surpass industry standards.</p>
                </div>
            </div>
            <div className="col-md-3">
                <div className="core" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                <img src={mission} /> 
                    <h3>Innovative Approach</h3>
                    <p>Constantly pushing boundaries
with state-of-the-art technology to
meet evolving client needs.</p>
                </div>
            </div>
            <div className="col-md-3">
                <div className="core" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                <img src={mission} /> 
                    <h3>Customer-Centric Philosophy</h3>
                    <p>Built on a legacy of delivering
                    exceptional printing solutions that
                    surpass industry standards.</p>
                </div>
            </div>
            <div className="col-md-3">
                <div className="core" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                <img src={mission} /> 
                    <h3>Artisans of Printing</h3>
                    <p>Transforming mechanical
processes into an art form that
eloquently communicates
emotions and experiences through
text and visuals.</p>
                </div>
            </div>
      </div>
    </div>
  </section>
  )
}

export default CoreValues
