import React from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import '../categories/categories.css'
import pro1 from '../../../assets/img/pro-1.jpg';
import pro2 from '../../../assets/img/pro-2.jpg';
import pro3 from '../../../assets/img/pro-3.jpg';
import pro4 from '../../../assets/img/pro-4.jpg';


function Categories() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        arrows: true,
        fade: false,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            }
        ]
    };
    return(
        <div className="categories default-padding">
        <div className="container">
        <div className="shop-txt">
                                <h2 className="shop-h2">DIVERSE PRODUCT RANGE</h2>
                                <p>Meeting Every Need</p>
                              
                            </div>
                            <Slider {...settings} className="SliderMain">
                         
                         

                            <div className="col-md-3 col-md-12">
                            <div className="product-grid">
                            <div className="product-image">
                                <a href="#" className="image">
                                    <img className="pic-1" src={pro1} />
                                    <img className="pic-2" src={pro1} />
                                </a>
                               {/* <span className="product-hot-label">hot</span>  */}
                                <a className="add-to-cart" href="#" data-tip="Add to cart">Add to cart<i className="fas fa-long-arrow-alt-right"></i></a>
                            </div>
                            <div className="product-content">
                                <h3 className="title"><a href="#">Stationery</a></h3>
                                {/* <div className="price">$17.00</div>
                                <a className="product-like-icon" href="#"><i className="far fa-heart"></i></a> */}
                            </div>
                        </div>
                            </div>

                           
                            <div className="col-md-3 col-md-12">
                            <div className="product-grid">
                            <div className="product-image">
                                <a href="#" className="image">
                                    <img className="pic-1" src={pro2} />
                                    <img className="pic-2" src={pro2} />
                                </a>
                               {/* <span className="product-hot-label">hot</span>  */}
                                <a className="add-to-cart" href="#" data-tip="Add to cart">Add to cart<i className="fas fa-long-arrow-alt-right"></i></a>
                            </div>
                            <div className="product-content">
                                <h3 className="title"><a href="#">Business Cards</a></h3>
                                {/* <div className="price">$17.00</div>
                                <a className="product-like-icon" href="#"><i className="far fa-heart"></i></a> */}
                            </div>
                        </div>
                            </div>

                            <div className="col-md-3 col-md-12">
                            <div className="product-grid">
                            <div className="product-image">
                                <a href="#" className="image">
                                    <img className="pic-1" src={pro3} />
                                    <img className="pic-2" src={pro3} />
                                </a>
                               {/* <span className="product-hot-label">hot</span>  */}
                                <a className="add-to-cart" href="#" data-tip="Add to cart">Add to cart<i className="fas fa-long-arrow-alt-right"></i></a>
                            </div>
                            <div className="product-content">
                                <h3 className="title"><a href="#">Corporate Gifts</a></h3>
                                {/* <div className="price">$17.00</div>
                                <a className="product-like-icon" href="#"><i className="far fa-heart"></i></a> */}
                            </div>
                        </div>
                            </div>

                            <div className="col-md-3 col-md-12">
                            <div className="product-grid">
                            <div className="product-image">
                                <a href="#" className="image">
                                    <img className="pic-1" src={pro4} />
                                    <img className="pic-2" src={pro4} />
                                </a>
                                 {/* <span className="product-hot-label">hot</span>  */}
                                <a className="add-to-cart" href="#" data-tip="Add to cart">Add to cart<i className="fas fa-long-arrow-alt-right"></i></a>
                            </div>
                            <div className="product-content">
                                <h3 className="title"><a href="#">Calendars</a></h3>
                                {/* <div className="price">$17.00</div> */}
                                <a className="product-like-icon" href="#"><i className="far fa-heart"></i></a>
                            </div>
                        </div>
                            </div>

                            
                            </Slider>  
        </div>
      </div>
    )
}



export default Categories;