import React from 'react'
import '../Services/services.css'
import serig from '../../../assets/img/serig.png'


const Services = () => {
  return (
    <section id="service">
    <div className="container-fluid">
      
      <div className="row">
        <div  className="col-md-6" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
            <div className='ser-img'>
                <img src={serig} />
            </div>
        </div>
                     
                    
                    <div className="col-md-6" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
                    
                    <div className="servs">
                    <div className="serv-txt">
                        <h2>Services</h2>
                        <p className="serv-txt-p">Enhancing Your Printing Experience</p>
                        </div>
                        <p>Nor Enterprises was established with a goal to bring our patrons 
    best of the world products with unmatched services.
    In light of this we undertake extraordinary efforts to source 
    unique products that would meet our customer needs and 
    enhance their brand values.</p>
                    <ul>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Graphic Design Services</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Variable Data Printing</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Large Format Printing</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Proofing Services</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Binding and Finishing</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Options</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Custom Orders</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Express or Rush Delivery</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Fulfillment Services</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Consultation and</li>
                        <li><i class="fa fa-pencil" aria-hidden="true"></i> Support</li>
                    </ul>
                </div>
            </div>
            
            
      </div>
    </div>
  </section>
  )
}

export default Services
