import React from 'react';
import './header.css';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';

function Navbar() {
  return (
    <div className="center-nav sticky-top">
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col-lg-3 col-md-7 offset-md-1 col-sm-7">
            <div className="logo">
               <a className="nav-link" href="/"><img src={Logo} alt="Logo" /> </a >
            </div>
          </div>

          <div className="col-lg-8 col-md-3 col-sm-3">
            <div className="nav">
              <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav mx-auto lab-ul">

                      {/* Use <a> for scrolling to sections */}
                      <li className="nav-item">
                        <a className="nav-link link-63" href="#home">Home</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link link-18" href="#about">About Us</a>
                      </li>

                      <li className="nav-item">
                         <a className="nav-link" href="#products">Products </a >
                      </li>

                      <li className="nav-item">
                         <a className="nav-link" href="#services">Services </a >
                      </li>

                      
                      <li className="nav-item">
                         <a className="nav-link" href="#key">Key Statistics </a >
                      </li>

                      <li className="nav-item">
                         <a className="nav-link" href="#client">Clients </a >
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
