import React from "react";
import { Link } from "react-router-dom";
import '../Header/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'; // Correct import

function Header() {
  return (
    <>
      <header id="home">
        <div className="top-nav">
          <div className="container-fluid">
            <div className="row justify-content-md-center">
              <div className="col-lg-6 col-md-12  col-sm-12">
                <div className="left-top-nav">
                  <Link className="nav-link" to="/">
                    <i className="fa fa-envelope" aria-hidden="true"></i> norenterprises21@gmail.com
                  </Link>
                  <Link className="nav-link" to="/">
                    <i className="fa fa-phone" aria-hidden="true"></i> <span> +91 9819031265 / 9892672189</span>
                  </Link>
                </div>
              </div>

              <div className="col-lg-6  col-md-12  col-sm-12">
                <div className="right-top-nav">
                  <ul>
                    {/* Facebook */}
                    <li>
                      <Link className="nav-link" to="/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook} className="fa-beat" style={{ '--fa-animation-duration': '0.5s' }} />
                      </Link>
                    </li>

                    {/* Twitter */}
                    <li>
                      <Link className="nav-link" to="/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} className="fa-beat" style={{ '--fa-animation-duration': '0.4s' }} />
                      </Link>
                    </li>

                    {/* Instagram */}
                    <li>
                      <Link className="nav-link" to="/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="fa-beat" style={{ '--fa-animation-duration': '0.3s' }} />
                      </Link>
                    </li>

                    {/* LinkedIn */}
                    <li>
                      <Link className="nav-link" to="/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} className="fa-beat" style={{ '--fa-animation-duration': '0.2s' }} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
