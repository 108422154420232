import React from "react";
import '../Contact/contact.css'


function Contact (){
      return(
            <div className="contact_us">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="contact_inner">
                            <div className="row">
                                    <div className="contact_form_inner">
                                        <div className="contact_field">
                                            <div className="heading-txt">
                                            <h3>Contact Us</h3>
                                            <p className="text-center">Feel Free to contact us any time. <br/>We will get back to you as soon as we can!.</p>
                                            </div>
                                            <input type="text" className="form-control form-group" placeholder="Name" />
                                            <input type="text" className="form-control form-group" placeholder="Email" />
                                            <input type="text" className="form-control form-group" placeholder="Contact" />
                                            <input type="text" className="form-control form-group" placeholder="Address" />
                                            <textarea className="form-control form-group" placeholder="Message" rows="5" ></textarea>
                                            <button className="contact_form_submit">Send</button>
                                        </div>
                                    </div>
                                </div>
                             
                            </div>

                    </div>
                </div>

                
 
            </div>
        </div>


       
          )
}

export default Contact;