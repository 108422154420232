
import React from "react";
import '../../Home/Client/client.css';
import client1 from '../../../assets/img/client-1.jpg'
import client2 from '../../../assets/img/client-2.jpg'
import client3 from '../../../assets/img/client-3.jpg'
import client4 from '../../../assets/img/client-4.jpg'
import client5 from '../../../assets/img/client-5.jpg'
import client6 from '../../../assets/img/client-6.jpg'
import client7 from '../../../assets/img/client-7.jpg'
import client8 from '../../../assets/img/client-8.jpg'

function Client() {
    return (
        <div className="logos">
            <div className="container">
            <div className="logo_item">
                <img src={client1} />
                <img src={client2} />
                <img src={client3} />
                <img src={client4} />
                <img src={client5} />
                <img src={client6} />
                <img src={client7} />
                <img src={client8} />
            <img src={client1} />
                <img src={client2} />
                <img src={client3} />
                <img src={client4} />
                <img src={client5} />
                <img src={client6} />
             
            </div>
        </div>
        </div>
    )
}
export default Client;