import React, { useState } from "react"; 
import Carousel from 'react-bootstrap/Carousel';
import '../Slider/banner.css';

function Carouselslider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="homeSlider">
      <div className="">
        <div className="row justify-content-center">
          <div className="col-md-12"> {/* Adjust for medium and large screens */}
            <div className="overlay"></div>
            <Carousel activeIndex={index} onSelect={handleSelect}>
              <Carousel.Item interval={3000}> 
                <img 
                  className="d-block w-100" 
                  src="./assets/img/banner-1.jpg" 
                  alt="Image One" 
                />
                <Carousel.Caption>
                  <h2 className="carousel-title">Welcome to <br/>Nor Enterprises</h2>
                  <p className="carousel-text">We make printing and gifting easy.</p>
                </Carousel.Caption>
              </Carousel.Item>
              
              <Carousel.Item interval={3000}> 
                <img 
                  className="d-block w-100" 
                  src="./assets/img/banner-4.jpg" 
                  alt="Image Two" 
                />
                <Carousel.Caption>
                  <h2 className="carousel-title">Welcome to <br/>Nor Enterprises</h2>
                  <p className="carousel-text">We make printing and gifting easy.</p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item interval={3000}> 
                <img 
                  className="d-block w-100" 
                  src="./assets/img/banner-3.jpg" 
                  alt="Image Three" 
                />
                <Carousel.Caption>
                  <h2 className="carousel-title">Welcome to <br/>Nor Enterprises</h2>
                  <p className="carousel-text">We make printing and gifting easy.</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carouselslider;
