import React, { useEffect, useState } from 'react';
import './key.css';

const CounterStats = () => {
  const stats = [
    { label: "Handles", count: 10, suffix: "M", text: "units Annually for corporate clients" },
    { label: "Handle over", count: 50000, text: "Shipments monthly nationwide" },
    { label: "Processes", count: 500000, text: "printed items Monthly for Corporate Bulk Orders" },
    { label: "Printing capacity of", count: 10, suffix: "M", text: "Pages per day" },
    { 
      label: "Use of", 
      count: 50, 
      suffix: "%", 
      text: "recycled paper For" 
    },
    { 
      label: "Use of", 
      count: 100, 
      suffix: "%", 
      text: "of print jobs within India" 
    },
    { label: "", count: 70, suffix: "%", text: "of orders come from existing corporate clients" },
  ];

  const [currentCounts, setCurrentCounts] = useState(stats.map(() => 0));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCounts((prevCounts) =>
        prevCounts.map((count, index) => {
          const targetCount = stats[index].count;
          if (targetCount !== null && count < targetCount) {
            return Math.min(count + Math.ceil(targetCount / 50), targetCount);
          }
          return count;
        })
      );
    }, 50);

    return () => clearInterval(interval);
  }, [stats]);

  return (
    <section id="counter-stats">
      <div className="container-fluid">
        <div className="heading-txt1">
          <h2>Key Statistics</h2>
          <p>Corporate Printing Operations</p>
        </div>
        <div className="row">
          {stats.map((stat, index) => (
            <div 
            className={`col-lg-3 stats`} 
            data-aos="zoom-in" 
            data-aos-offset="300" 
            data-aos-duration="1500" 
            key={index}
          >
              <h5>{stat.label}</h5>
              <div className="counting">
                {currentCounts[index].toLocaleString()}
                {stat.suffix && <span>{stat.suffix}</span>}
              </div>
              <h5>{stat.text}</h5>
          
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CounterStats;
